html, body, #app {
  width: 100%;
  height: 100%;
}
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

